.carousel_container {
    margin-bottom: 100px;

    img {
        width: 100%;
    }
}


@media screen and (max-width: 720px) {
    .carousel_container {
        margin-bottom: 0;
    }
}
  