.topbar_container {
  position: fixed;
  background: black;
  top: 0;
  color: white;
  text-align: left;
  width: 100%;
  padding: 15px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 111111;

  span {
    .image_container-profile {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 1px solid #C2C2C2;
      vertical-align: middle;
      margin-top: -12px;
      margin-right: 15px;
    }

    padding: 15px;
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }

  .topbar_profile {
    position: absolute;
    right: 0;
    height: calc(100% - 15px);
    display: inline-block;
    padding:15px;

    i {
      margin-left: 15px;
    }

    .topbar_profile-settings {
      border-right: 1px solid #C2C2C2
    }
    .topbar_profile-lang {

    }
  }
}