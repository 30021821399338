.showreel_container {
    position: relative;
    height: 50vh;
    overflow: hidden;
    background: url('./../medias/images/miyis_showreel_poster.jpg') center center;
    video {
        position: absolute;
        width: 120%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}


@media screen and (max-width: 730px) {
    .showreel_container {
        height: 45vh;
    }
}

@media screen and (max-width: 500px) {
    .showreel_container {
        height: 40vh;
    }
}



