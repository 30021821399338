.sidebar_container {
  position: fixed;
  background: white;
  top: 83px;
  width: 300px;
  height: 100vh;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: transform 0.3s ease-in-out;

  &.collapse {
    transform: translateX(-65%);
  }

  .sidebar_header {
    text-align: right;
    cursor: pointer;

    i {
      padding: 15px;
    }
  }

  a {
    color: black;
    text-decoration: none;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-align: left;

    li {
      padding: 30px;
      font-family: $fontP;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: normal;
      letter-spacing: 0.15em;
      border-top: 1px solid #E5E5E5;
      cursor: pointer;

      &:hover {
        background: $colorAccent;
        color: white;
      }

      i {
        margin-right: 15px;
      }
    }
  }
}