.banner_container {
  width: 100%;
  padding: 150px 0;
  position: relative;

  &.white {
    background: white;
  }

  &.black {
    background: black;

    &::before {
      background: black;
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0.8;
    }
  }

  p {
    position: relative;
    font-family: $fontQuote;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    padding: 50px 0;

    &::before {
      display: block;
      position: absolute;
      width: 50px;
      height: 3px;
      content: "";
      top: 0;
      left: 35%;
    }

    &::after {
      display: block;
      position: absolute;
      width: 50px;
      height: 3px;
      content: "";
      bottom: 0;
      right: 35%;
    }

    &.white {
      color: black;
      &::before {
        background: black;
      }
      &::after {
        background: black;
      }
    }

    &.black {
      color: white;
      &::before {
        background: white;
      }
      &::after {
        background: white;
      }
    }
  }

}