.cows {
  position: absolute;
  bottom:0;
  left: 0;

  .cow {
    transform: scaleX(-1);
    width: 100px;
    height: 100px;
  }
}


.trees_container {
  position: absolute;
  width: 100px;
  height: 100px;

  &.first {
    bottom: -20px;
    left: 0;
    animation: treesRising 0.75s ease-in-out;
  }

  &.second {
    bottom: -10px;
    left: 70%;
    transform: scale(-1.5, 1.5);
    animation: treesRisingInverse 0.65s forwards ease-in-out;
  }

  &.third {
    bottom: -20px;
    left: 80%;
    animation: treesRising 1s ease-in-out;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}


@keyframes treesRising {
  from { transform: translateY(100%) }
  to { transform: translateY(0) }
}

@keyframes treesRisingInverse {
  from { transform: scale(-1.5, 1.5) translateY(100%) }
  to { transform: scale(-1.5, 1.5) translateY(0) }
}