.modal_container {
  position:absolute;
  background: white;
  text-align: left;
  width: 400px;
  padding: 15px;
  top: 15px;
  right:15px;
  border-radius: 3px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  animation: appear 0.32s ease-out forwards;

  h4 {
    font-family: $fontTitle;
    font-style: normal;
    font-weight: bold;
    text-transform: uppercase;
    line-height: normal;
    text-align: left;
    letter-spacing:0.35em;
  }

  &.leave {
    animation: appear 0.32s ease-out forwards reverse;
  }

  .modal_header {
    text-align: right;
    i {
      padding: 5px;
      cursor: pointer;
    }
  }

  ul {
    margin: 0;
    padding: 0;
  }


  li {
    vertical-align: top;
    padding: 15px;
    margin: 0;
    list-style-type: none;
    border-bottom: 1px solid #C4C4C4;
    cursor: pointer;

    &:hover {
      background: #F2F2F2;
    }

    &:last-child {
      border: none;
    }

    img {
      margin-right: 15px;
    }
  }
}

.modal-anim-enter {
  opacity: 0;
}
.modal-anim-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.modal-anim-exit {
  opacity: 1;
}
.modal-anim-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}



@keyframes appear {
  0%   {
    opacity:0;
    transform: translateX(100%);
  }
  100% {
    opacity:1;
    transform: translateX(0);
  }
}