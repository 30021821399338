.login_page {
  background: #F2F2F2;
  width: 100vw;
  height: 100vh;
  display: block;
  position: fixed;
  top:0;
  left:0;
  z-index: 1111;

  &.leaving {
    animation: remove-animation 0.6s ease-in;
  }
}

.login_subtitle {
  margin-top: 50px;
}

.login_container {
  background: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  border-radius: 5px;
  border: 1px solid #F2F2F2;
  padding: 15px;
  box-shadow: 0px 6px 19px rgba(0, 0, 0, 0.25);
  animation: appear-animation 0.4s ease-in-out;

  .login_header {
    padding: 30px 0;
  }

  input[type="checkbox"] {
    margin-right: 5px;
  }

  .login_input {
    position: relative;
    background: #F2F2F2;
    display: flex;
    align-items: baseline;

    i {
      margin-right: 15px;
      margin-left: 15px;
    }
    input {
      background: inherit;
      width: 100%;
    }



    .login_input-visible {
      position: absolute;
      bottom: 0;
      right: 0;
      height: calc(100% - 15px);
      i {
        vertical-align: sub;
      }
    }
  }

  label {
    text-align: left;
    display: block;
    padding: 15px;

    input {
      padding: 10px;
      margin-top: 10px;
      border-radius: 3px;
      border: none;
    }
  }

}

.login_input-message {
  color: red;
  padding: 10px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.4s ease;

  &.error {
    opacity: 1;
  }
}


.login_infos {
  margin-top: 50px;
  p {
    font-size: 14px;
    line-height: 18px;
  }
}


// btn admin
.btn_admin {
  background: $colorAccent;
  color: white;
  border-radius: 5px;
  min-width: 200px;
  margin: 15px;
  border: none;
  cursor: pointer;

  input {
    background: none;
    color: inherit;
    border: none;
    padding: 15px;
    cursor: inherit;
  }

  &.btn_admin-border {
    border: 1px solid $colorAccent;
    color: $colorAccent;
    background: white;
  }

}



@media screen and (max-width: 720px) {
  .login_page {
    position: initial;
    width: 100%;
    background: white;
  }
  
  .login_subtitle {
    margin-top: 0px;
    font-size: 14px;
    line-height: 16px;
  }

  .login_container {
    position: initial;
    transform: translate(0,0);
    width: 100%;
    box-shadow: none;
    border: none;
    border-radius: 0;
  }
}



@keyframes appear-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes remove-animation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}