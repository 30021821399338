button {
  outline: none;
}

.link_container {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px 0;

  &.black {
    background: black;
  }
}

.btn_link {
  color: black;
  background: none;
  font-family: $fontTitle;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 0.35em;
  padding: 10px;
  border: none;
  border-bottom: 2px solid black;
  cursor: pointer;
  transition: all 0.31s ease-out;

  i {
    transition: transform 0.31s ease-out;
  }

  &:hover {
    letter-spacing: 0.45em;
    i {
      transform: translateX(15px);
    }
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }

  &.black {
    color: white;
    border-bottom: 1px solid white;
  }
}

.btn_cta {
  color: white;
  background-color: white;
  font-family: $fontTitle;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 0em;
  padding: 15px 30px;
  border-radius: 3px;
  cursor: pointer;
  margin: 50px 15px 5px 0px;
  border: none;
  transition: background-color 0.22s ease-in-out;

  &:hover {
    background: darken($colorAccent, 10%);
  }

  i {
    margin-left: 10px;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }

  input[type="submit"] {
    display: inline-block;
    color: inherit;
    background: inherit;
    font-family: inherit;
    font-style: inherit;
    font-weight: inherit;
    font-size: inherit;
    text-transform: uppercase;
    line-height: inherit;
    letter-spacing: inherit;
    margin-right: 10px;
    border: none;
    cursor: pointer;
  }
}

// btn helpers
.color {
  background: $colorAccent;
  color: white;
}
.fullSize {
  width: 100%;
}
.border {
  background: transparent;
  border: 1px solid white;
  transition: all 0.31s ease-out;

  i {
    transition: transform 0.31s ease-out;
  }

  &:hover {
    background: transparent;
    i {
      transform: translateX(5px);
    }
  }
}

.btn_magnifier {
  background-color: $colorAlternate;
  color: white;
  display: block;
  width: 150px;
  height: 150px;
  cursor: pointer;
  border: none;
  z-index: 0;
  transition: background-color 0.21s ease-out;

  i {
    font-style: normal;
    font-size: 36px;
    transition: font-size 0.21s ease-out;
  }

  &:hover {
    background-color: darken($colorAlternate, 3%);
    i {
      font-size: 28px;
    }
  }
}

.btn-lang {
  margin-right:15px;
  &.active {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .link_container {
    position: relative;
    margin-top: 50px;
  }

  .btn_magnifier {
    width: 50px;
    height: 50px;

    i {
      font-size: 16px;
    }
  }
}