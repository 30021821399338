.notFound_container {
  margin: 50px 30%;
}

.notFound_title {
  font-size: 10rem;
  margin: 0;
  letter-spacing: 0.5rem;
}

.notFound_subTitle {
  font-weight: bold;
  font-size: 1.6rem;
}