.filterbar_container {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #C4C4C4;
  text-align: right;

  .filterbar_searchbar {
    background: white;
    width: 600px;
    border-radius: 3px;
    
    input {
      display: inline-block;
      border: none;
      border-bottom: 1px solid #F2F2F2;
    }
    
    i {
      display: inline-block;
    }
  }

  .filterbar_menu {
    display: inline-block;
    margin-left: 15px;

    button {
      background: transparent;
      margin: 0 5px;
      border: none;
      cursor: pointer;

      &:hover {
        color: $colorAccent;
      }
    }
  }
}

// media gallery
.media_gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;

  label {
    background: #C4C4C4;
    width: 20%;
    min-width: 250px;
    min-height: 250px;
    cursor: pointer;
  }

  input {
    display: none!important; // hide the original CSS for the input file
  }
}