.dashboard_container {
  margin: 0 auto;
  padding: 15px;

  .dashboard_container-card {
    background-color: white;
    border-radius: 5px;

    .dashboard_card {
      background: #F2F2F2;
      text-align: left;
      margin: 30px;
      padding: 50px 15px 50px 15%;
      border-radius: 5px;
      
      label {
        padding: 15px;
        text-align: left;
        margin-bottom: 15px;
      }
      
      input, textarea {
        margin: 0 auto;
        padding: 15px;
        border-radius: 3px;
      }

      input[type=text] {
        width: 100%;
      }

      textarea {
        min-width: 100%;
        width: 100%;
        max-width: 100%;
        height: 200px;
      }

    }
  }
}


// dashboard form
.dashboard_form {

}

.dashboard_label-file {
  background: yellow;
  display: block;
  overflow: hidden;
  width: 100%;
  max-height: 250px;
  cursor: pointer;

  input {
    display: none;
  }
}

.dashboard_input {
  border: 1px solid #C4C4C4;
  margin: 15px 0!important;
}