
// styles colors
$colorBackgroundAlternate: #e6e6e6;
$colorText: #484847;
$colorTextAlternate: white;
$colorMain: #B73B3B;
$colorAlternate: #F44F4F;
$colorAccent: #218eff;

// styles fonts
$fontTitle: 'Work Sans', sans-serif;
$fontP: 'Work Sans', sans-serif;
$fontQuote: 'Work Sans', serif;

//base...
@import 'generic/normalize.scss';
@import 'generic/box-sizing.scss';

//dashboard...
@import 'dashboard/dashboard.scss';
@import 'dashboard/sidebar.scss';
@import 'dashboard/topbar.scss';
@import 'dashboard/filterbar.scss';
@import 'login-form.scss';

//components...
@import '404.scss';
@import 'navbar.scss';
@import 'jumbotron.scss';
@import 'leaflet-map.scss';
@import 'stats-block.scss';
@import 'banner.scss';
@import 'cookie-banner.scss';
@import 'gallery-viewer.scss';
@import 'graphics.scss';
@import 'image.scss';
@import 'info-contact.scss';
@import 'content.scss';
@import 'full-content.scss';
@import 'buttons.scss';
@import 'contact-form.scss';
@import 'shoebox.scss';
@import 'showreel.scss';
@import 'carousel.scss';
@import 'modal.scss';


// react-image-gallery temp slideshow
@import "node_modules/react-image-gallery/styles/scss/image-gallery.scss";

// lightbox custom css
@import '~react-image-lightbox/style.css';
// video-react custom css
@import '~video-react/styles/scss/video-react';


html {
  text-align: center;
  font-family: $fontP;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-container {
  margin:0 auto;
}

h1 {
  color: black;
  font-family: $fontTitle;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  text-transform: uppercase;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.1em;
}

h2 {
  font-family: $fontTitle;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  text-transform: uppercase;
  line-height: normal;
  text-align: center;
  letter-spacing:0.1em;
}

h3, h4 {
  font-family: $fontTitle;
  margin-top: 50px;
}

p {
  color: $colorText;
  font-family: $fontP;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.05em;
}

a {
  color: $colorAccent;
}

img {
  user-select: none;
  cursor: pointer;
}

input, textarea {
  outline: none;
}

textarea {
  height: 45px;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-family: $fontP;
}

.home-video-container {
  height: 70vh;
  position: relative;
  overflow: hidden;
}

.home-video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  left: 0;
}

.header_container {
  position: relative;
  width: 100%;
  height: 60vh;
  background: url("./../medias/slides/Team2.jpg") center center;
  background-size: cover;
  h1 {
    position: absolute;
    font-size: 48px;
    padding: 0 15px;
    top: 19%;
    left: 5%;
    color: white;
    background: rgba(0,0,0,0.85);
  }
}

.container {
  max-width: 66%;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}

.section_container {
  position: relative;
  display: flex;
  padding: 15px;
  /* min-height: 50vh; */
}

.special_list_container {
  ul {
    margin: 50px 0 100px;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    list-style-type: none;

    li {
      padding: 15px;
      margin: 5px 0;
      width: 33%;
      background: white;
      border-left: 3px solid $colorAccent;
    }
  }
}

.team_list {
  margin-left: 30px;
  li {
    padding: 10px 0;
    font-weight: bold;
    list-style-type: initial;
  }
}

.copyright_container {
  padding: 0 0 5px;

  i {
    color: black;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  li {
    display: inline-block;
    margin: 0 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.15em;
    a {
      color: black;
    }
  }

  p {
    font-size: 11px;
    text-align: center;
  }
}

iframe {
  border-width: 0px;
}

// columns
.col-2 {
  vertical-align: top;
  padding: 5px;
  display: inline-block;
  width: 50%;
}



// font awesome icones
.fas {
  margin-left:5px;
}

// text helpers
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-bold {
  font-weight: bold;
  text-transform: uppercase;
  color: black;
}

// layout fixes
.margin_bottom-350 {
  margin-bottom: 350px;
}

// visual helpers
.hidden {
  opacity: 0;
}

// content animation
.display-animation {
  animation: displayAnimation 0.7s forwards ease-out;
}

.appear-left {
  animation: appearLeft 0.7s forwards ease-out;
}

// preloader
#preloader {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 48px;
  height: 48px;
  margin-left: -24px;
  margin-top: -24px;
  border: #C4C4C4 3px solid;
  border-left-color: transparent;
  border-radius: 50%;
  animation: rotating 1s linear infinite;
}

// slick carousel
.carousel_container {
  width: 75%;
  margin: 0 auto 100px;

}

// animations
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes displayAnimation {
  from {
    opacity: 0;
    transform: translateY(50%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes appearLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

// media queries
@media screen and (max-width: 1200px) {
  .col-2 {
    width: 100%;
  }

  .margin_bottom-350 {
    margin-bottom: 150px;
  }
}


@media screen and (max-width: 960px) {
  .mainHeader_container {
    height: 500px;
  }

  .area_container {
    flex-wrap: wrap;
  }

  .image_container {
    width: 100%!important;
    height: 500px;
    order: 1;
  }


  .full_container {
    span {
      max-width: 100%!important;
    }
    ul {
      li {
        width: 100%;
      }
    }
  }

  // inView react-intersection-observer
  .inView_container {
    width: 100%!important;
  }

  .section_container {
    flex-wrap: wrap;

    .content_container {
      width: 100%!important;
      order: 2;
    }
  }
}

@media screen and (max-width: 640px) {
  h1 {
    font-size: 22px;
  }

  .container {
    max-width: 90%;
  }

  .margin_bottom-350 {
    margin-bottom: 0;
  }

  .home-video-container {
    max-height: 40vh;
  }

  .mainHeader_container {
    height: 300px;

    video {
      width: 300%;
    }
  }

  .image_container, .content_container {
    min-width: 100%!important;
  }
  .image_container {
    height: 200px;
  }
}
