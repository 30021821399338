// Header
.navbar_container {
  display: flex;
  width: 100%;
  min-height: 45px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 25;
  background: white;
}

.btn_burger {
  border: none;
  position: absolute;
  right:0;
  background: none;
  padding: 5px 10px;
  font-size: 28px;
}

@media screen and (min-width: 1200px) {
  .btn_burger {
    display:none;
  }
}

.menu_container {
  width: 100%;
}

.desktop_container {
  text-align: right;
  color: grey;
  position: relative;
  width: 100%;
  height: 100%;
  line-height: 45px;
  font-family: $fontTitle;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.15em;

  a {
    color: inherit;
    display: inline-block;
    padding: 15px 25px;
    text-decoration: inherit;
    transition: all 0.4s ease-in-out;
    &:hover {
      background-color: $colorBackgroundAlternate;
      color: black;
    }
  }


  .menu_item {
    padding:0 15px;
    text-decoration: none;
    position: relative;
    color: #C4C4C4;
    overflow: hidden;

    &[aria-current="page"] {
      color: black;
      &::after {
        left: 0;
      }
    }

    &::after {
      display: block;
      position: absolute;
      bottom: 0;
      left: -100%;
      width: 100%;
      height: 5px;
      background: $colorAccent;
      content: "";
      transition: left 0.31s ease-out;
    }

    &:hover {
      &::after {
        left: 0;
      }
    }

    &:hover, &:active, &:focus, &:focus-within {
      color: black;
    }
  }
}

@media screen and (max-width: 1200px) {
  .desktop_container {
    display:none;
  }
}

.burger_container {
  display: block;
  position: absolute;
  top: 45px;
  right: 0;
  background: white;
  width: 90vw;
  height: 100vh;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  &.opening {
    animation: appear 0.5s ease;
  }

  &.closing {
    animation: disappear 0.5s ease;
  }

  a {
    color: black!important;
  }

  .menu_item {
    color: inherit;
    text-decoration: inherit;
  }

  ul {
    display: block;
    margin: 0;
    padding: 0;
    width: 100%;

    li {
      color: black;
      text-align: left;
      font-family: $fontTitle;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 0.35em;
      width: 100%;
      list-style-type: none;
      margin-left: 15px;

      border-bottom: 1px solid #F2F2F2;

      i {
        margin-right: 15px;
      }

      a {
        display: block;
        padding: 25px 10px;
        text-decoration: none;
      }
    }
  }
}

.logo_container {
  a {
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    z-index: 1;
  }
  img {
    width: 180px;
    
  }
}

@keyframes appear {
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
}

@keyframes disappear {
  from {
    right: 0;
  }
  to {
    right: -100%;
  }
}


/* Animation Burger Toggle */

#nav-icon3 {
  width: 30px;
  height: 45px;
  position: relative;
  top:5px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon3 span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: black;
  border-radius: 12px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon3 span:nth-child(1) {
  top: 0px;
}

#nav-icon3 span:nth-child(2),#nav-icon3 span:nth-child(3) {
  top: 10px;
}

#nav-icon3 span:nth-child(4) {
  top: 20px;
}

#nav-icon3.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

#nav-icon3.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}