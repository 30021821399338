// Image
.image_container {
    position: relative;
    overflow: hidden;
    width: 60%;
    padding: 50px;
    .image_source {
      width: 100%;
      height: 100%;
      display: block;
      background-position: center;
      background-size: cover;
    }
  }
  
  .image_placeholder {
    background: #C4C4C4;
    position: relative;
    display: block;
    height: 100%;
    
    i {
      position: absolute;
      font-size: 64px;
      color: $colorText;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @media screen and (max-width: 460px) {
    .image_container {
        padding: 15px;
    }
  }