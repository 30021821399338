// leaflet map
#mapid {
  height: 180px;
}

.leaflet-tile-pane {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  z-index: auto;
}

.map_container {
  display: block;
  width: 100%;
  height: 500px;
}

@media screen and (max-width: 640px) {
  .map_container {
    height: 200px;
  }
}